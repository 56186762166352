:global {
  .ant-layout-header {
    background: #3689fc;
  }
}

.layout {
  min-height: 100vh;
  background-color: hsl(220, 23%, 97%);
  background-image: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 10%);
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 64px 1fr;
  grid-template-areas:
    "head head head"
    "sider content content"
    "sider footer footer";

  .header {
    top: 0;
    width: 100%;
    height: 64px;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    padding-left: 16px;
    padding-right: 16px;
    position: -webkit-sticky;
    position: sticky;
    grid-area: head;
    z-index: 999;
    align-self: stretch;

    .headerLeft{
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;

      .headerLeftLogo{
        height: 48px;
      }

      .headerLeftTitle{
        font-size: 16px;
        font-weight: bold;
        color: #FFFFFF;
        margin: 0 16px;
      }
    }
    
    .headerRight{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    
    .headerRightText{
      color: #FFFFFF;
      margin-left: 16px;
    }
  }

  .sider {
    max-width: 200px;
    grid-area: sider;
    position: -webkit-sticky;
    background-color: #FFFFFF;
    position: sticky;
    top: 64px;
    max-height: calc(100vh - 64px);
    box-sizing: border-box;
    padding-top: 20px;
    border-right: 1px solid rgba(5, 5, 5, 0.06);

    .siderChildren {
      display: flex;
      flex-direction: column;
      overflow: auto;
      height: 100%;

      .siderTriggers {
        width: 100%;
        height: 40px;
        border-top: 1px solid rgba(5, 5, 5, 0.06);
        display: flex;
        font-size: 20px;
        flex-direction: row;
        align-items: center;
        transition: all .3s;
      }
    }
  }

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    z-index: 10;
    grid-area: content;
  }
}
